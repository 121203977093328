import React from 'react';
import Wrapper from './../components/Wrapper/Wrapper';
import styled from 'styled-components';

const StyledErrorHeader = styled.h1`
  font-size: 180px;
  color: ${({ theme }) => theme.colors.primaryGreen};
  letter-spacing: -10px;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    font-size: 130px;
  }
`;

const StyledErrorParagraph = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.secondaryBlue};
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.light};
`;

const ErrorPage = () => (
  <Wrapper flex column height="70vh">
    <StyledErrorHeader>404</StyledErrorHeader>
    <StyledErrorParagraph>
      Strona o podanym adresie nie istnieje
    </StyledErrorParagraph>
  </Wrapper>
);

export default ErrorPage;
